.advertisement-card-skeleton {
  display: block;
  width: auto;
  display: table;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 auto;
  position: relative;
  top: 0;
  left: 0;
  background-color: transparent;
  width: 100%;
  max-width: 720px;
  min-height: 90px;
  ion-skeleton-text {
    --border-radius: 5px;
    --background-rgb: 0,0,0;
    width: 720px;
    max-width: 720px;
    height: 90px;
    margin: 0;
  }
}

@media(max-width: 800px){
  .advertisement-card-skeleton {
    max-width: 320px;
    max-height: 100px;
    ion-skeleton-text {
      width: 320px;
      height: 100px;
    }
  }
}
