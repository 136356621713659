.watching-loading {
  padding: 15px 0px;
  background: var(--bg_product_primary);

  .advertisement-card-skeleton ion-skeleton-text {
    --background-rgb: 255,255,255;
  }

  :not(.advertisement-card-skeleton) ion-skeleton-text {
    --border-radius: 5px;
    height: 20px;
    width: 100%;
    max-width: 100px;
  }

  ion-breadcrumbs {
    ion-skeleton-text {
      height: 20px !important;
      width: 100px !important;
    }
  }

  .share-menu-skeleton {
    width: 40px !important;
    height: 40px !important;
    --border-radius: 50% !important;
  }

  .loading-title {
    width: 100%;
    display: block;
    max-width: 600px !important;
    width: 300px !important;
    height: 40px !important;
    margin: 0 auto;
  }

  .loading-banner {
    width: 100% !important;
    max-width: 800px !important;
    height: auto !important;
    aspect-ratio: 16/9;
  }
  .intro {
    max-width: 300px !important;
  }
  .loading-content {
    width: 100% !important;
    max-width: unset !important;
    margin-bottom: 15px;
    &:last-of-type {
      width: 70% !important;
    }
  }
}
