.content-block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding: 5px 15px;
  
  &.even {
    flex-direction: row-reverse;
    &.nosotros {
      flex-direction: row;
      background: rgba(0,0,0, 0.1);
      gap: 15px;
    }
  }
  
  .text-container {
    width: 100%;
  }

  .embed-wrapper {
    width: 100%;
  }

  ion-img {
    width: 200px;
    height: auto;
    &::part(image) {
      width: 200px;
      height: auto;
    }
  }

  &.news {
    background: rgba(88, 187, 0, 0.7);
    background: -moz-linear-gradient(
      left,
      rgba(88, 187, 0, 0.7) 0%,
      rgba(88, 187, 0, 0.7) 25%,
      rgba(88, 187, 0, 0) 100%
    );
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(88, 187, 0, 0.7)),
      color-stop(25%, rgba(88, 187, 0, 0.7)),
      color-stop(100%, rgba(88, 187, 0, 0))
    );
    background: -webkit-linear-gradient(
      left,
      rgba(88, 187, 0, 0.7) 0%,
      rgba(88, 187, 0, 0.7) 25%,
      rgba(88, 187, 0, 0) 100%
    );
    background: -o-linear-gradient(
      left,
      rgba(88, 187, 0, 0.7) 0%,
      rgba(88, 187, 0, 0.7) 25%,
      rgba(88, 187, 0, 0) 100%
    );
    background: -ms-linear-gradient(
      left,
      rgba(88, 187, 0, 0.7) 0%,
      rgba(88, 187, 0, 0.7) 25%,
      rgba(88, 187, 0, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(88, 187, 0, 0.7) 0%,
      rgba(88, 187, 0, 0.7) 25%,
      rgba(88, 187, 0, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#58bb00', endColorstr='#58bb00', GradientType=1 );

    &.even {
      background: rgba(88, 187, 0, 0);
      background: -moz-linear-gradient(
        left,
        rgba(88, 187, 0, 0) 0%,
        rgba(88, 187, 0, 0.7) 75%,
        rgba(88, 187, 0, 0.7) 100%
      );
      background: -webkit-gradient(
        left top,
        right top,
        color-stop(0%, rgba(88, 187, 0, 0)),
        color-stop(75%, rgba(88, 187, 0, 0.7)),
        color-stop(100%, rgba(88, 187, 0, 0.7))
      );
      background: -webkit-linear-gradient(
        left,
        rgba(88, 187, 0, 0) 0%,
        rgba(88, 187, 0, 0.7) 75%,
        rgba(88, 187, 0, 0.7) 100%
      );
      background: -o-linear-gradient(
        left,
        rgba(88, 187, 0, 0) 0%,
        rgba(88, 187, 0, 0.7) 75%,
        rgba(88, 187, 0, 0.7) 100%
      );
      background: -ms-linear-gradient(
        left,
        rgba(88, 187, 0, 0) 0%,
        rgba(88, 187, 0, 0.7) 75%,
        rgba(88, 187, 0, 0.7) 100%
      );
      background: linear-gradient(
        to right,
        rgba(88, 187, 0, 0) 0%,
        rgba(88, 187, 0, 0.7) 75%,
        rgba(88, 187, 0, 0.7) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#58bb00', endColorstr='#58bb00', GradientType=1 );
    }
  }

  &.media {
    background: rgba(227, 126, 32, 0.7);
    background: -moz-linear-gradient(
      left,
      rgba(227, 126, 32, 0.7) 0%,
      rgba(227, 126, 32, 0.7) 25%,
      rgba(227, 126, 32, 0) 100%
    );
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(227, 126, 32, 0.7)),
      color-stop(25%, rgba(227, 126, 32, 0.7)),
      color-stop(100%, rgba(227, 126, 32, 0))
    );
    background: -webkit-linear-gradient(
      left,
      rgba(227, 126, 32, 0.7) 0%,
      rgba(227, 126, 32, 0.7) 25%,
      rgba(227, 126, 32, 0) 100%
    );
    background: -o-linear-gradient(
      left,
      rgba(227, 126, 32, 0.7) 0%,
      rgba(227, 126, 32, 0.7) 25%,
      rgba(227, 126, 32, 0) 100%
    );
    background: -ms-linear-gradient(
      left,
      rgba(227, 126, 32, 0.7) 0%,
      rgba(227, 126, 32, 0.7) 25%,
      rgba(227, 126, 32, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(227, 126, 32, 0.7) 0%,
      rgba(227, 126, 32, 0.7) 25%,
      rgba(227, 126, 32, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e37e20', endColorstr='#e37e20', GradientType=1 );

    &.even {
      background: rgba(227, 126, 32, 0);
      background: -moz-linear-gradient(
        left,
        rgba(227, 126, 32, 0) 0%,
        rgba(227, 126, 32, 0.7) 75%,
        rgba(227, 126, 32, 0.7) 100%
      );
      background: -webkit-gradient(
        left top,
        right top,
        color-stop(0%, rgba(227, 126, 32, 0)),
        color-stop(75%, rgba(227, 126, 32, 0.7)),
        color-stop(100%, rgba(227, 126, 32, 0.7))
      );
      background: -webkit-linear-gradient(
        left,
        rgba(227, 126, 32, 0) 0%,
        rgba(227, 126, 32, 0.7) 75%,
        rgba(227, 126, 32, 0.7) 100%
      );
      background: -o-linear-gradient(
        left,
        rgba(227, 126, 32, 0) 0%,
        rgba(227, 126, 32, 0.7) 75%,
        rgba(227, 126, 32, 0.7) 100%
      );
      background: -ms-linear-gradient(
        left,
        rgba(227, 126, 32, 0) 0%,
        rgba(227, 126, 32, 0.7) 75%,
        rgba(227, 126, 32, 0.7) 100%
      );
      background: linear-gradient(
        to right,
        rgba(227, 126, 32, 0) 0%,
        rgba(227, 126, 32, 0.7) 75%,
        rgba(227, 126, 32, 0.7) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e37e20', endColorstr='#e37e20', GradientType=1 );
    }
  }

  &.radio {
    background: rgba(231, 201, 0, 0.7);
    background: -moz-linear-gradient(
      left,
      rgba(231, 201, 0, 0.7) 0%,
      rgba(231, 201, 0, 0.7) 25%,
      rgba(231, 201, 0, 0) 100%
    );
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(231, 201, 0, 0.7)),
      color-stop(25%, rgba(231, 201, 0, 0.7)),
      color-stop(100%, rgba(231, 201, 0, 0))
    );
    background: -webkit-linear-gradient(
      left,
      rgba(231, 201, 0, 0.7) 0%,
      rgba(231, 201, 0, 0.7) 25%,
      rgba(231, 201, 0, 0) 100%
    );
    background: -o-linear-gradient(
      left,
      rgba(231, 201, 0, 0.7) 0%,
      rgba(231, 201, 0, 0.7) 25%,
      rgba(231, 201, 0, 0) 100%
    );
    background: -ms-linear-gradient(
      left,
      rgba(231, 201, 0, 0.7) 0%,
      rgba(231, 201, 0, 0.7) 25%,
      rgba(231, 201, 0, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(231, 201, 0, 0.7) 0%,
      rgba(231, 201, 0, 0.7) 25%,
      rgba(231, 201, 0, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e7c900', endColorstr='#e7c900', GradientType=1 );

    &.even {
      background: rgba(231, 201, 0, 0);
      background: -moz-linear-gradient(
        left,
        rgba(231, 201, 0, 0) 0%,
        rgba(231, 201, 0, 0.7) 75%,
        rgba(231, 201, 0, 0.7) 100%
      );
      background: -webkit-gradient(
        left top,
        right top,
        color-stop(0%, rgba(231, 201, 0, 0)),
        color-stop(75%, rgba(231, 201, 0, 0.7)),
        color-stop(100%, rgba(231, 201, 0, 0.7))
      );
      background: -webkit-linear-gradient(
        left,
        rgba(231, 201, 0, 0) 0%,
        rgba(231, 201, 0, 0.7) 75%,
        rgba(231, 201, 0, 0.7) 100%
      );
      background: -o-linear-gradient(
        left,
        rgba(231, 201, 0, 0) 0%,
        rgba(231, 201, 0, 0.7) 75%,
        rgba(231, 201, 0, 0.7) 100%
      );
      background: -ms-linear-gradient(
        left,
        rgba(231, 201, 0, 0) 0%,
        rgba(231, 201, 0, 0.7) 75%,
        rgba(231, 201, 0, 0.7) 100%
      );
      background: linear-gradient(
        to right,
        rgba(231, 201, 0, 0) 0%,
        rgba(231, 201, 0, 0.7) 75%,
        rgba(231, 201, 0, 0.7) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e7c900', endColorstr='#e7c900', GradientType=1 );
    }
  }

  &.podcast,
  &.yokupo {
    background: rgba(2, 188, 154, 0.7);
    background: -moz-linear-gradient(
      left,
      rgba(2, 188, 154, 0.7) 0%,
      rgba(2, 188, 154, 0.7) 25%,
      rgba(2, 188, 154, 0) 100%
    );
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(2, 188, 154, 0.7)),
      color-stop(25%, rgba(2, 188, 154, 0.7)),
      color-stop(100%, rgba(2, 188, 154, 0))
    );
    background: -webkit-linear-gradient(
      left,
      rgba(2, 188, 154, 0.7) 0%,
      rgba(2, 188, 154, 0.7) 25%,
      rgba(2, 188, 154, 0) 100%
    );
    background: -o-linear-gradient(
      left,
      rgba(2, 188, 154, 0.7) 0%,
      rgba(2, 188, 154, 0.7) 25%,
      rgba(2, 188, 154, 0) 100%
    );
    background: -ms-linear-gradient(
      left,
      rgba(2, 188, 154, 0.7) 0%,
      rgba(2, 188, 154, 0.7) 25%,
      rgba(2, 188, 154, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(2, 188, 154, 0.7) 0%,
      rgba(2, 188, 154, 0.7) 25%,
      rgba(2, 188, 154, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02bc9a', endColorstr='#02bc9a', GradientType=1 );

    &.even {
      background: rgba(2, 188, 154, 0);
      background: -moz-linear-gradient(
        left,
        rgba(2, 188, 154, 0) 0%,
        rgba(2, 188, 154, 0.7) 75%,
        rgba(2, 188, 154, 0.7) 100%
      );
      background: -webkit-gradient(
        left top,
        right top,
        color-stop(0%, rgba(2, 188, 154, 0)),
        color-stop(75%, rgba(2, 188, 154, 0.7)),
        color-stop(100%, rgba(2, 188, 154, 0.7))
      );
      background: -webkit-linear-gradient(
        left,
        rgba(2, 188, 154, 0) 0%,
        rgba(2, 188, 154, 0.7) 75%,
        rgba(2, 188, 154, 0.7) 100%
      );
      background: -o-linear-gradient(
        left,
        rgba(2, 188, 154, 0) 0%,
        rgba(2, 188, 154, 0.7) 75%,
        rgba(2, 188, 154, 0.7) 100%
      );
      background: -ms-linear-gradient(
        left,
        rgba(2, 188, 154, 0) 0%,
        rgba(2, 188, 154, 0.7) 75%,
        rgba(2, 188, 154, 0.7) 100%
      );
      background: linear-gradient(
        to right,
        rgba(2, 188, 154, 0) 0%,
        rgba(2, 188, 154, 0.7) 75%,
        rgba(2, 188, 154, 0.7) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02bc9a', endColorstr='#02bc9a', GradientType=1 );
    }
  }

  &.even {
    ion-skeleton-text:last-child {
      width: 250px;
    }
  }

  &.odd {
    ion-skeleton-text:first-child {
      width: 250px;
    }
  }
}

@media (max-width: 576px) {
  .content-block {
    display: flex;
    flex-direction: column-reverse !important;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 5px;

    &.news,
    &.news.even {
      background: rgba(87, 187, 0, 0.7);
      background: -moz-linear-gradient(
        top,
        rgba(87, 187, 0, 0.7) 0%,
        rgba(87, 187, 0, 0.7) 25%,
        rgba(87, 187, 0, 0) 100%
      );
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(87, 187, 0, 0.7)),
        color-stop(25%, rgba(87, 187, 0, 0.7)),
        color-stop(100%, rgba(87, 187, 0, 0))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(87, 187, 0, 0.7) 0%,
        rgba(87, 187, 0, 0.7) 25%,
        rgba(87, 187, 0, 0) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(87, 187, 0, 0.7) 0%,
        rgba(87, 187, 0, 0.7) 25%,
        rgba(87, 187, 0, 0) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(87, 187, 0, 0.7) 0%,
        rgba(87, 187, 0, 0.7) 25%,
        rgba(87, 187, 0, 0) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(87, 187, 0, 0.7) 0%,
        rgba(87, 187, 0, 0.7) 25%,
        rgba(87, 187, 0, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#57bb00', endColorstr='#57bb00', GradientType=0 );
    }

    &.media,
    &.media.even {
      background: rgba(227, 126, 32, 0.7);
      background: -moz-linear-gradient(
        top,
        rgba(227, 126, 32, 0.7) 0%,
        rgba(227, 126, 32, 0.7) 25%,
        rgba(227, 126, 32, 0) 100%
      );
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(227, 126, 32, 0.7)),
        color-stop(25%, rgba(227, 126, 32, 0.7)),
        color-stop(100%, rgba(227, 126, 32, 0))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(227, 126, 32, 0.7) 0%,
        rgba(227, 126, 32, 0.7) 25%,
        rgba(227, 126, 32, 0) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(227, 126, 32, 0.7) 0%,
        rgba(227, 126, 32, 0.7) 25%,
        rgba(227, 126, 32, 0) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(227, 126, 32, 0.7) 0%,
        rgba(227, 126, 32, 0.7) 25%,
        rgba(227, 126, 32, 0) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(227, 126, 32, 0.7) 0%,
        rgba(227, 126, 32, 0.7) 25%,
        rgba(227, 126, 32, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e37e20', endColorstr='#e37e20', GradientType=0 );
    }

    &.radio,
    &.radio.even {
      background: rgba(231, 201, 0, 0.7);
      background: -moz-linear-gradient(
        top,
        rgba(231, 201, 0, 0.7) 0%,
        rgba(231, 201, 0, 0.7) 25%,
        rgba(231, 201, 0, 0) 100%
      );
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(231, 201, 0, 0.7)),
        color-stop(25%, rgba(231, 201, 0, 0.7)),
        color-stop(100%, rgba(231, 201, 0, 0))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(231, 201, 0, 0.7) 0%,
        rgba(231, 201, 0, 0.7) 25%,
        rgba(231, 201, 0, 0) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(231, 201, 0, 0.7) 0%,
        rgba(231, 201, 0, 0.7) 25%,
        rgba(231, 201, 0, 0) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(231, 201, 0, 0.7) 0%,
        rgba(231, 201, 0, 0.7) 25%,
        rgba(231, 201, 0, 0) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(231, 201, 0, 0.7) 0%,
        rgba(231, 201, 0, 0.7) 25%,
        rgba(231, 201, 0, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e7c900', endColorstr='#e7c900', GradientType=0 );
    }

    &.yokupo,
    &.yokupo.even,
    &.podcast,
    &.podcast.even {
      background: rgba(2, 188, 154, 0.7);
      background: -moz-linear-gradient(
        top,
        rgba(2, 188, 154, 0.7) 0%,
        rgba(2, 188, 154, 0.7) 25%,
        rgba(2, 188, 154, 0) 100%
      );
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(2, 188, 154, 0.7)),
        color-stop(25%, rgba(2, 188, 154, 0.7)),
        color-stop(100%, rgba(2, 188, 154, 0))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(2, 188, 154, 0.7) 0%,
        rgba(2, 188, 154, 0.7) 25%,
        rgba(2, 188, 154, 0) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(2, 188, 154, 0.7) 0%,
        rgba(2, 188, 154, 0.7) 25%,
        rgba(2, 188, 154, 0) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(2, 188, 154, 0.7) 0%,
        rgba(2, 188, 154, 0.7) 25%,
        rgba(2, 188, 154, 0) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(2, 188, 154, 0.7) 0%,
        rgba(2, 188, 154, 0.7) 25%,
        rgba(2, 188, 154, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02bc9a', endColorstr='#02bc9a', GradientType=0 );
    }
  }
}
