.read-loading {
  background: #fff;
  :not(.advertisement-card-skeleton) ion-skeleton-text {
    --border-radius: 5px;
    --background-rgb: 0,0,0;
    height: 20px;
    width: 100%;
    max-width: 100px;
  }

  ion-breadcrumbs {
    ion-skeleton-text {
      height: 20px !important;
      width: 100px !important;
    }
  }

  .share-menu-skeleton {
    width: 40px;
    height: 40px;
    --border-radius: 50%;
  }

  .loading-title {
    width: 100%;
    display: block;
    max-width: 600px;
    height: 40px;
  }

  .autor-loading {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 5px;

    ion-skeleton-text:first-child {
      --border-radius: 50%;
      height: 30px;
      width: 30px;
    }
    ion-skeleton-text:last-child {
      width: 80px;
    }
  }
  .loading-banner {
    width: 100% !important;
    max-width: 800px !important;
    height: auto !important;
    aspect-ratio: 16/9;
  }
  .intro {
    max-width: 300px !important;
  }
  .loading-content {
    width: 100% !important;
    max-width: unset !important;
    margin-bottom: 15px;
    &:last-of-type {
      width: 70% !important;
    }
  }
}
