.media-card-skeleton {
  border: 2px solid var(--media_color);
  width: 250px;
  height: 303px;
  display: block;
  margin: 5px auto;
  position: relative;
  top: 0;
  left: 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
  ion-skeleton-text {
    margin: 0;
  }
}