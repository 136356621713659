.editor-profile {
  background: var(--bg_sidebar);
  border: 0 solid var(--ion-color-secondary);
  border-bottom-width: 2px;
  padding: 10px;
  text-align: center;
  .editor-name {
    width: auto;
    margin: 5px auto;
    max-width: 90%;
    line-height: 1.2;
    font-weight: 700;
  }
  .presentation {
    font-size: 0.95em;
    line-height: 1.2;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    font-style: italic;
  }
  ion-img {
    border-radius: 50%;
    border: 2px solid var(--ion-color-secondary);
    color: transparent;
    width: 200px;
    height: 200px;
    display: grid;
    place-items: center;
    margin: 5px auto 10px auto;
    overflow: hidden;
  }

  .contact-wrapper {
    display: flex;
    width: 100%;
    max-width: 340px;
    margin: 0 auto 5px auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 10px;
    ion-button {
      --background: var(--ion-color-primary);
      --border-radius: 50%;
      --padding-start: 0;
      --padding-end: 0;
      color: #fff;
      margin: 5px auto;
      min-width: 40px;
      width: 40px;
      max-width: 40px;
      min-height: 40px;
      height: 40px;
      max-height: 40px;
    }
  }
}
