.block7 {
  .item-gallery-wrapper {
    width: 100%;
    max-width: 315px;
    figure {
      width: 95%;
      max-width: 300px;
      height: auto;
      margin: 5px auto;
      overflow: hidden;
      ion-img {
        border-radius: 5px;
        overflow: hidden;
      }
    }
  }
}