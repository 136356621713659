.editor-loading {
  background: var(--bg_sidebar);
  border: 0 solid var(--ion-color-secondary);
  border-bottom-width: 2px;
  padding: 10px;
  text-align: center;
  ion-skeleton-text {
    border-radius: 10px;
  }
  .editor-name-skeleton {
    width: auto;
    margin: 5px auto;
    max-width: 90%;
    height: 35px;
  }
  .presentation-skeleton {
    width: 90%;
    max-width: 500px;
    margin: 0 auto 5px auto;
    height: 20px;
  }
  .editor-photo-skeleton {
    border-radius: 50%;
    border: 2px solid var(--ion-color-secondary);
    color: transparent;
    width: 200px;
    height: 200px;
    display: grid;
    place-items: center;
    margin: 5px auto 10px auto;
    overflow: hidden;
  }

  .contact-wrapper {
    display: flex;
    width: 100%;
    max-width: 340px;
    margin: 0 auto 5px auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 5px;
    .button-skeleton {
      margin: 5px auto;
      border-radius: 50%;
      min-width: 40px;
      width: 40px;
      max-width: 40px;
      min-height: 40px;
      height: 40px;
      max-height: 40px;
    }
  }
}
