.search-category-card {
  display: block;
  width: 300px;
  height: 300px;
  position: relative;
  top: 0;
  left: 0;
  border: 2px solid var(--ion-color-primary);
  border-radius: 10px;
  margin: 5px auto;
  overflow: hidden;
  box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);

  &.news {
    border-color: var(--news_color_dark);
  }
  &.media {
    border-color: var(--media_color_dark);
  }
  figure {
    margin: 0;
    width: 300px;
    height: 300px;
  }
  figcaption {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: -1px;
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    p {
      font-size: 24px;
      font-weight: 700;
      text-shadow: 1px 1px 1px #000;
      margin: 0 auto;
      width: auto;
      text-align: center;
      word-wrap: break-word;
    }
  }
}

@media (max-width: 992px) {
  .search-category-card {
    width: 200px;
    height: 200px;
    
    figure {
      width: 200px;
      height: 200px;
    }
    figcaption {
      width: 200px;
      height: 200px;
      p {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 575px) {
  .search-category-card {
    width: 150px;
    height: 150px;
    
    figure {
      width: 150px;
      height: 150px;
    }
    figcaption {
      width: 150px;
      height: 150px;
      p {
        font-size: 14px;
      }
    }
  }
}
