.tv-page.loading-live {
  color: #fff;
  text-align: center;
  padding-top: 20px;

  .page-title {
    float: left;
  }

  ion-skeleton-text {
    --border-radius: 5px;

    &.video {
      width: 100%;
      aspect-ratio: 16/9;
      margin: 0 auto;
    }  
  }
}