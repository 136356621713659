.highlighted-card-skeleton {
  width: 150px;
  height: 150px;
  margin: 15px auto;
  background: #1a0054;
  border: 1px solid #12003b;
  border-radius: 15px;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, .75);
  overflow: hidden;
  border: 2px solid var(--media_color);
}
@media (max-width: 992px) {
  .highlighted-card-skeleton {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 768px) {
  .highlighted-card-skeleton {
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 400px) {
  .highlighted-card-skeleton {
    width: 90px;
    height: 90px;
  }
}