.single-image-container {
  display: block;

  figure {
    display: block;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    overflow: hidden;
    ion-img {
      border-radius: 5px;
      overflow: hidden;
    }
  }
}
