.content-swiper {
  display: block;
  width: 100%;
  margin: 25px auto;  
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 5px;
  overflow: hidden;

  &.--backgrounded {
    background-color: rgba(255,255,255, 0.05);
  }

  .swiper.swiper-horizontal {
    width: 95%;
    margin: 0 auto;
    padding-bottom: 0;
    &.--have-pagination {
      padding-bottom: 30px;
    }
    &.--have-navigation {
      padding-left: 40px;
      padding-right: 40px;
    }
    
  }

  .swiper-title {
    font-weight: 700;
    font-size: 1.5rem;
    margin: 10px 20px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    display: inline-block;

    &.--default {
      color: #fff;
    }

    &.--primary {
      color: var(--ion-color-primary);
    }

    &.--underlined {
      border: 0 solid var(--ion-color-primary);
      border-bottom-width: 2px;
      padding-right: 20px;
      padding-bottom: 5px;
    }
  }

  .swiper-link {
    font-weight: 700;
    font-size: 1.2rem;
    margin: 10px 20px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    display: inline-block;
    float: right;

    &.--default {
      color: #fff;
    }

    &.--primary {
      color: var(--ion-color-primary);
    }

    &.--underlined {
      border: 0 solid var(--ion-color-primary);
      border-bottom-width: 2px;
      padding-bottom: 5px;
    }
  }

  &.news {
    .swiper-title {
      &.--primary {
        color: var(--news_color);
      }
      &.--underlined {
        border: 0 solid var(--news_color);
        border-bottom-width: 2px;
      }
    }
    .swiper-link {
      &.--primary {
        color: var(--news_color);
      }
      &.--underlined {
        border: 0 solid var(--news_color);
        border-bottom-width: 2px;
      }
    }
  }

  &.media {
    .swiper-title {
      &.--primary {
        color: var(--media_color);
      }
      &.--underlined {
        border: 0 solid var(--media_color);
        border-bottom-width: 2px;
      }
    }
    .swiper-link {
      &.--primary {
        color: var(--media_color);
      }
      &.--underlined {
        border: 0 solid var(--media_color);
        border-bottom-width: 2px;
      }
    }
  }

}