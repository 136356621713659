.chapter-card {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  border: 0 solid var(--media_color_dark);
  border-bottom-width: 2px;
  padding: 20px 0;
  margin: 0 auto;
  max-width: 90%;

  .cover-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  figure {
    padding: 0;
    margin: 0;
    width: 300px;
    ion-img {
      width: 300px;
      height: auto;
    }
  }
  figcaption {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
  .title {
    color: var(--media_color);
    font-weight: 700;
    padding-bottom: 5px;
  }
  .intro {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .program-title {
    float: right;
    color: var(--media_color);
    font-weight: 700;
    font-size: 14px;
  }
}
@media(max-width: 650px) {
  .chapter-card {
    max-width: 95%;
    .cover-wrapper {
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}
