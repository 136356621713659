.share-menu {
  display: flex;
  position: relative;
  height: 40px;
  width: 40px;

  ion-fab-button {
    --color: #ffffff;
    --background: var(--ion-color-primary);
    width: 40px;
    height: 40px;
    margin: 0;
  }

  &.news {
    ion-fab-button {
      --background: var(--news_color_dark);
    }
  }
  &.media {
    ion-fab-button {
      --background: var(--media_color_dark);
    }
  }

  ion-fab-list {
    background-color: rgba(255,255,255,0.2);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }

  &.--dark {
    ion-fab-list {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  button {
    margin: 5px auto;
    width: 44px;
    height: 44px;
    background: var(--ion-color-secondary) !important;
    font-size: 24px !important;
    border-radius: 50%;
    line-height: 52px !important;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
    

    &.facebook {
      background: var(--facebook-color) !important;
      color: #fff !important;
    } 

    &.telegram {
      background: var(--telegram-color) !important;
      color: #fff !important;
    }

    &.twitter {
      background: var(--twitter-color) !important;
      color: #fff !important;
    }

    &.whatsapp {
      background: var(--whatsapp-color) !important;
      color: #fff !important;
    }
    &.email {
      background-color: var(--media_color_dark) !important;
      color: #fff !important;
    }
  }

  svg {
    pointer-events: none;
  }
}
