.program-body {
  width: 95%;
  max-width: 1080px;
  margin: 10px auto;

  .season-selector {
    margin: 20px 0 10px 20px;
  }

  ion-select {
    width: 150px;
    color: var(--media_color);
    background: rgba(32,17,17,0.8);
    border-radius: 10px;
  }
}
