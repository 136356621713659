.navigation-reel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  /* min-height: 155px; */


  figure {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    background: #02000e;
    border-radius: 15px;
    margin: 15px auto 0 auto;
    overflow: hidden;
    box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
  }

  a {
    i {
      margin-left: 5px;
    }
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 15px;
    font-size: 1.8rem;
    color: #fff;
    opacity: 0.6;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: 700;
    letter-spacing: 1px;
  }
}

@media(max-width: 400px){
  .navigation-reel {
    min-height: 155px;
    figure,
    figcaption,
    a {
      width: 140px;
      height: 140px;
      font-size: 1.4rem;
      line-height: 140px;
    }
  }
}
@media(min-width: 401px) and (max-width: 599px){
  .navigation-reel {
    min-height: 195px;
    figure,
    figcaption,
    a {
      width: 180px;
      height: 180px;
      font-size: 1.4rem;
      line-height: 175px;
    }
  }

}
@media(min-width: 600px) and (max-width: 959px){
  .navigation-reel {
    min-height: 265px;
    figure,
    figcaption,
    a {
      width: 250px;
      height: 250px;
      line-height: 245px;
    }
  }

}
@media(min-width: 960px) and (max-width: 1279px){
  .navigation-reel {
    min-height: 315px;
    figure,
    figcaption,
    a {
      width: 300px;
      height: 300px;
      line-height: 295px;
    }
  }

}
@media(min-width: 1280px) and (max-width: 1919px){
  .navigation-reel {
    min-height: 240px;
    figure,
    figcaption,
    a {
      width: 450px;
      height: 225px;
      line-height: 295px;
    }
  }
}
@media(min-width: 1920px){
  .navigation-reel {
    min-height: 315px;
    figure,
    figcaption,
    a {
      width: 600px;
      height: 300px;
      line-height: 295px;
    }
  }
}
