.program-loading {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px;
  background-color: var(--bg_sidebar);
  background-size: cover;
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border: 0 solid var(--bg_sidebar);
  border-bottom-width: 2px;
  padding-top: 20px;

  ion-skeleton-text {
    --border-radius: 5px;
    height: 20px;
    margin: 5px auto;
    min-width: 90px;
  }
  
  .content-container {
    width: 100%;
    max-width: 450px;
    margin-left: 10%;
  }

  .img-logo {
    width: 300px;
    height: 300px;
    margin: 20px auto;
    --border-radius: 50%;
  }

  ion-breadcrumbs {
    background: rgba(32,17,17,0.8);
    display: inline-flex;
    padding: 5px 15px;
    border-radius: 5px;
    color: #fff;
    ion-breadcrumb.breadcrumb-active {
      color: #fff;
    }
  }

  .classification-container {
    background-color: rgba(32,17,17,0.8);
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 15px;
    display: block;
  }
  .share-buttons-container {
    position: relative;
    margin-top: 10px;

    ion-skeleton-text {
      width: 40px;
      height: 40px;
      min-width: 40px;
      --border-radius: 50%;
    }
  }
}

@media(max-width: 767px) {
  .program-header {
    padding: 20px 10px;
  
    .content-container {
      margin: 0 auto;
    }
  }
}

@media(max-width: 399px) {
  .program-header {
    .img-logo {
      width: 250px;
      height: 250px;
    }
  }
}
