.contents-page {
  &::part(scroll) {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .spinner-circular {
    height: 60px;
    width: 60px;
  }
}
