.read-related {
  text-align: center;
  padding: 20px;

  .related-title {
    color: #fff;
    border: 0 solid var(--news_color_dark);
    border-bottom-width: 2px;
    display: inline-block;
    letter-spacing: 1.5px;
    margin: 10px 0 15px 0;
    font-weight: 700;
    text-transform: uppercase;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 5px;
  }
}
