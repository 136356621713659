.terms-conditions-page {
  --background: var(--bg_product_primary);

  .loading-text {
    height: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .loading-title {
    height: 40px;
    width: 250px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .separated {
    margin-top: 20px;
  }

  /* -- text -- */
  a {
    color: var(--ion-color-primary);
    font-weight: 700;
  }
  
  ul,
  ol {
    margin-bottom: 5px;
    padding-left: 10px;
  }

  p,
  p span,
  blockquote p,
  blockquote p span,
  li {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 0.92em;
    line-height: 1.5em;
    font-weight: 400;
    font-family: var( --ion-font-family);
    word-break: break-word;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h1 span,
  h2 span,
  h3 span,
  h4 span,
  h5 span,
  h6 span {
    margin-bottom: 5px;
    font-family: var( --ion-font-family);
    font-weight: 700;
    line-height: 1.6em;
    word-break: break-word;
  }
}
