.tv-page.no-live {
  color: #fff;
  text-align: center;
  --padding-start: 20px;
  --padding-end: 20px;

  &>section {
    background-color: rgba(255,255,255, 0.05);
    border-radius: 10px;
    padding: 15px;
    margin: 10px auto;
    &:first-of-type {
      margin-top: 30px;
    }
    &:last-of-type {
      margin-bottom: 30px;
    }
  }

  .read-news {
    font-size: 1.3em;
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;
  }

  .content-swiper {
    margin-top: 0;
    margin-bottom: 0;
  }

  h1,
  h2 {
    font-weight: 700;
  }

  h2 {
    font-size: 1.3em;
  }

  h1 {
    font-size: 1.5rem;
  }

  .notification {
    text-align: center;
    ion-img {
      width: 150px;
      height: 150px;
      display: block;
      margin: 5px auto;
    }
  }

  .navigation {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 35px;
    a {
      color: #fff;
      display: inline-block;
      margin: 5px 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      border: 0 solid var(--ion-color-primary);
      border-bottom-width: 3px;
      padding: 2px 10px;
      font-weight: 700;
    }
  }
}

@media (max-width: 768px) {
  .not-found-page {
    --padding-start: 10px;
    --padding-end: 10px;
  }
}