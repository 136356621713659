.watch-related {
  text-align: center;
  padding: 30px 20px 20px 20px;
  border: 0 solid var(--media_color_dark);
  border-top-width: 2px;
  background: var(--bg_product_secondary);

  .related-title {
    color: #fff;
    border: 0 solid var(--media_color_dark);
    border-bottom-width: 2px;
    display: inline-block;
    letter-spacing: 1.5px;
    margin: 10px 0 15px 0;
    font-weight: 700;
    text-transform: uppercase;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 5px;
  }
}
