.mobile-app {
  ion-tab-bar {
    --background: var(--bg_sidenav);
    --color: #cccccc;
    ion-tab-button {
      --color: #cccccc;
    }
    .tab-selected {
      color: #fff;
    }
  }
  
}
