.search-input {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: block;

  .search-button {
    --background: transparent;
    --border-radius: 50%;
    --color: #000;
    --padding-bottom: 0;
    --padding-end: 0;
    --padding-start: 0;
    --padding-top: 0;
    position: absolute;
    z-index: 200;
    left: 10px;
    max-width: 40px;
    max-height: 40px;
    width: 50px;
    height: 50px;
    top: 3px;
    font-size: 20px;
    margin: 0;
  }

  ion-input {
    --background: #fff;
    --color: #000;
    --padding-start: 50px;
    --padding-end: 20px;
    border-radius: 40px;  
    box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
    margin-bottom: 10px;
  }
}
