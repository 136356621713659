.block8 {
  .swiper-container {
    max-width: 1080px;
    padding-bottom: 40px;
  }
  
  .swiper-slide {
    width: 315px !important;
  }

  figure {
    display: block;
    margin: 0 auto;
    width: 95%;
    border-radius: 5px;
    overflow: hidden; 
  }
}