

.advertisement-card {
  display: block;
  width: 100%;
  margin: 10px auto;

  figure {
    width: auto;
    display: table;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    background-color: transparent;
    width: 100%;
    max-width: 720px;
    min-height: 90px;
    ion-skeleton-text {
      width: 720px;
      max-width: 720px;
      height: 90px;
      --border-radius: 5px;
      margin: 0;
    }
    ion-img {
      border-radius: 2px;
      box-shadow: 2px 2px 3px 0px rgba(54,54,54,1);
      margin: 0 auto;
      display: block;
      color: transparent;
      overflow: hidden;
      img {
        border-radius: 5px;
      }
    }
    figcaption {
      opacity: 0.6;
      position: absolute;
      right: 0;
      top: 0;
      line-height: 1.2;
      font-size: 0.6rem;
      color: #fff;
      background: #000;
      padding: 2px 17px 2px 3px;
      text-align: center;
      border-radius: 2px;  
      p {
        margin: 2px;
        font-weight: 700;
      }
      ion-button {
        position: absolute;
        right: 1px;
        top: 1px;
        margin: 0;
        text-transform: none;
        height: 16px;
        width: 16px;
        --color: #fff;
        --background: #000;
        --border-radius: 50%;
        --padding-start: 0;
        --padding-end: 0;
      }
    }
  }

  &.mobil {
    figure {
      max-width: 320px;
      max-height: 100px;
      ion-skeleton-text {
        width: 320px;
        height: 100px;
      }
    }
  }
}
