.contact-us-page {
  --padding-top: 30px;
  --padding-bottom: 30px;

  ion-grid {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
  }

  .align-center {
    text-align: center;
  }

  .page-title {
    margin: 0 auto;
    color: #1a1a1a;
  }

  ion-item {
    --background: #fff;
    --color: #1a1a1a;
    min-height: 68px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-end;
    align-items: flex-start;

    ion-input,
    ion-select {
      height: 42px;
    }
    &::part(native) {
      padding: 0;
    } 
  }

  ion-button {
    --border-radius: 10px;
    --background: var(--bg_primary_color);
    --color: #fff;
    display: block;
    width: 100px;
    margin: 10px auto;
  }
}
