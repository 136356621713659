.highlighted-navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 0 auto;
  width: 100%;
}
@media (max-width: 767px) {
  .highlighted-navigation {
    max-width: 320px;
  }
}