.watch-preview {
  padding: 10px 0 20px 0;
  margin-bottom: 10px;

  .status-alert {
    border: 2px solid;
    text-align: center;
    width: 300px;
    margin: 10px auto;
    border-radius: 10px;

    &.por-aprobar {
      border-color: rgba(255, 102, 0, 1);
      background-color: rgba(255, 102, 0, 1);
      color: #fff;
    }

    &.inhabilitado {
      border-color: rgba(153, 0, 0, 1);
      background-color: rgba(153, 0, 0, 1);
      color: #fff;
    }
  }

  .chapter-preview {
    width: 90%;
    margin: 0 auto;

    img {
      margin: 0 auto 10px auto;
      display: block;
    }

    p {
      margin-bottom: 2px;
      line-height: 1.3em;

      &.intro {
        color: #b3b3b3;
      }
    }
  }
}
