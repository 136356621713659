.story-card {
  border-radius: 50%;
  display: flex;
  height: 100px;
  width: 100px;
  margin: 5px auto;
  overflow: hidden;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--media_color);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.75);
}
@media (max-width: 768px) {
  .story-card {
    height: 70px;
    width: 70px;
  }
}