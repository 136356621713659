.watch-content {
  padding: 15px 0px;
  background: var(--bg_product_primary);

  ion-breadcrumbs {
    ion-breadcrumb.breadcrumb-active {
      color: #fff;
    }
  }

  .content-title {
    margin: 0;
    font-weight: 700;
    text-align: center;
  }

  .item-gallery-wrapper {
    padding: 10px;
  }

  @media (max-width: 599px) {
    .item-gallery-wrapper {
      padding: 15px 0;
    }
  }

  .watch-body {
    a:hover {
      color: var(--media_color_dark);
    }
    blockquote {
      border-color: var(--media_color_dark);
    }
    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: var(--media_color_dark);
    }
    single-image figure {
      figcaption {
        border-bottom-color: var(--media_color_dark);
      }
    }
    /* -- text -- */
    ul,
    ol {
      margin-top: 0px;
      margin-bottom: 10px;
      padding-left: 10px;
    }

    p,
    p span,
    blockquote p,
    blockquote p span,
    li {
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 0.92em;
      line-height: 1.5em;
      font-weight: 400;
      font-family: var( --ion-font-family);
      word-break: break-word;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    h1 span,
    h2 span,
    h3 span,
    h4 span,
    h5 span,
    h6 span {
      font-size: 1.05em !important;
      margin-bottom: 5px;
      font-family: var( --ion-font-family);
      font-weight: 700;
      line-height: 1.6em;
      word-break: break-word;
    }

    a {
      color: var(--media_color_dark);
      font-weight: 700;
    }
    /* -- text -- */

    /* -- image directive --*/
    .photo-single {
      margin: 5px auto;
      display: block;
    }

    single-image figure {
      max-width: 600px;

      figcaption {
        font-size: 15px;
        padding: 10px 0 5px 0;
        border: 0 solid var(--media_color_dark);
        margin: 0 15px;
        text-align: center;
        border-bottom-width: 1px;
      }
    }

    /* -- image directive --*/
    blockquote {
      border: 5px solid var(--media_color_dark);
      border-top: 0;
      border-right: 0;
      border-bottom: 0;
      margin-left: 15px;
      padding-left: 15px;
      font-style: italic;
    }
  }

}


