.program-header {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px;
  background-color:  #000;
  background-size: cover;
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border: 0 solid var(--bg_sidebar);
  border-bottom-width: 2px;
  padding-top: 20px;
  
  .content-container {
    width: 100%;
    max-width: 450px;
    margin-left: 10%;
  }

  .img-logo {
    display: block;
    width: 300px;
    height: 300px;
    margin: 0 auto 20px auto;
    color: transparent;
  }

  ion-breadcrumbs {
    background: rgba(32,17,17,0.8);
    display: inline-flex;
    padding: 5px 15px;
    border-radius: 5px;
    color: #fff;
    ion-breadcrumb.breadcrumb-active {
      color: #fff;
    }
  }

  .classification-container {
    background-color: rgba(32,17,17,0.8);
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 15px;
    display: block;
  }
  .classification {
    color: var(--media_color_dark);
    margin: 0px;
    font-size: 14px;
    .classification-item {
      padding-right: 20px;
    }
  }
  .title {
    color: #fff;
    text-align: center;
    margin: 0px;
    font-weight: 700;
  }
  .intro {
    margin: 0 0 5px 0 ;
    font-size: 15px;
  }

  .share-buttons-container {
    position: relative;
    margin-top: 10px;

    .share-menu {
      position: relative;
    }
  }
}

@media(max-width: 767px) {
  .program-header {
    padding: 20px 10px;
  
    .content-container {
      margin: 0 auto;
    }
  }
}

@media(max-width: 399px) {
  .program-header {
    .img-logo {
      width: 250px;
      height: 250px;
    }
  }
}
