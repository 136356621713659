.news-card {
  border: 1px solid var(--bg_default_color);
  position: relative;
  top: 0;
  left: 0;
  border-radius: 10px;
  overflow: hidden;
  width: 300px;
  height: 245px;
  margin: 10px auto;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);

  figcaption {
    background: var(--bg_default_color);
    position: absolute;
    bottom: 0;
    padding: 10px 10px 10px 10px;
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
    width: 300px;
    min-height: 75px;
    a {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 600;
      display: block;
      text-align: left;
      max-height: 58px;
      overflow: hidden;

      &.news-category {
        margin: 0;
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 12px;
        background: var(--news_color_dark);
        padding: 5px 15px;
        border-radius: 10px;
      }
    }
  }

  &.have-category {
    min-height: 285px;
    padding-bottom: 40px;
    figcaption {
      padding-bottom: 40px;
      height: 115px;
    }
  }
}
