.home-page {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}
.home-page .content-swiper.story-cards {
  margin-top: 15px;
}


@media (max-width: 991px) {
  .home-page {
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-top: 10px;
    --padding-bottom: 10px;
  }
}