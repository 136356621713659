.search-page {
  --padding-top: 30px;
  --padding-bottom: 30px;

  

  .page-title {
    margin-top: 0px;
    margin-bottom: 20px;
  }
}
