.news-card-skeleton {
  border: 1px solid var(--bg_default_color);
  position: relative;
  top: 0;
  left: 0;
  border-radius: 10px;
  overflow: hidden;
  width: 300px;
  height: 245px;
  margin: 10px auto;
  padding-bottom: 32px;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
  ion-skeleton-text {
    margin: 0;
  }

  figcaption {
    width: 300px;
    background: var(--bg_default_color);
    font-weight: 700;
    position: absolute;
    bottom: 0;
    line-height: 1.2;
    padding: 5px 10px 10px 10px;
    font-size: 18px;
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
    
    ion-skeleton-text {
      height: 18px;
      margin: 5px 0;
      border-radius: 10px;
      height: 18px;
      &:nth-child(3) {
        width: 70%;
      }
    }

    .category-title {
      margin: 0;
      position: absolute;
      bottom: 10px;
      right: 10px;
      background: var(--background);
      padding: 0px 15px;
      border-radius: 10px;
      width: 50%;
      ion-skeleton-text {
        height: 14px;
      }
    }
  }
  &.have-category {
    min-height: 285px;
    padding-bottom: 40px;
    figcaption {
      padding-bottom: 40px;
      height: 115px;
    }
  }
}

